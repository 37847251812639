.rootSnackbar {
  width: 250px;
  height: 30px;
  background: transparent;
}
.rootSnackbar div{
  min-width: 100px;
  padding: 0;
  background: transparent;
}

.rootSnackbar button {
  font-weight: bold;
  font-size: 16px;
}

.videoSnackbar {
  background: transparent;
}

.videoSnackbar >div{
  display: flex;
  justify-content: space-around;
}


.videoSnackbar div{
  min-width: 100px;
  padding: 0;
  background: transparent;
}


.videoSnackbar button {
  height: 30px;
}

