.closeIcon {
  border-radius: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.divider {
  margin: 0 23px 10px 24px;
  max-width: 100%;
  background: #C4C4C4;
  height: 1px;
}

@media (max-width: 915px) {
  .divider {
    margin: 0 23px 4px 24px;
  }
}

.chat {
  width: 320px;
  height: 446px;
}

.channel {
  width: 100%;
  height: 300px;
}

.messageList_desktop {
  height: 340px;
  overflow-y: auto;
}

.messageList_mobile {
  height: 190px;
  overflow-y: auto;
}

@media (max-width: 915px) {
  .messageList_mobile {
    height: 290px;
  }
}


@media (max-width: 860px) {
  .messageList_mobile {
    height: 280px;
  }
}

@media (max-height: 350px) {
  .messageList_mobile {
    height: 190px;
  }
}

@media (max-width: 550px) {
  .messageList_mobile {
    height: 310px;
  }
}

.messageList::-webkit-scrollbar {
  width: 0;
}

.message {
  margin-bottom: 24px;
}
