.wrapper {
  display: flex;
  column-gap: 10px;
  overflow: hidden;
  padding: 0 24px 0 30px;
}

.date {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #292D32;
}

.wrapperText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4px;
  overflow: hidden;
}

.messageInner {
  background: #FFFFFF;
  border: 0;
  border-radius: 0;
  padding: 0;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #292D32;
}

.messageInner p {
  margin: 0;
}

.user {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #292D32;
}

.localUser {
  color: rgba(37, 134, 255, 1);
}
