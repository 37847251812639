.root {
  border: 2px solid #FFFFFF;
  border-radius: 16px;
  background: #FFFFFF;
  transition: .2s;
  cursor: pointer;
}

.root > img {
  background-size: contain;
}

.root:hover {
  border: 2px solid #2586FF;
  transition: .2s;
}

.root:focus {
  border: 2px solid #2586FF;
  transition: .2s;
}


.root:active {
  border: 2px solid #2586FF;
  transition: .2s;
}
