.root_mobile {
  position: absolute;
  right: 44px;
  bottom: 22px;
  display: flex;
  column-gap: 20px;
}

.root_desktop {
  position: absolute;
  bottom: 36px;
  right: 28px;
  display: flex;
  column-gap: 20px;
}

.handBlock {
  position: absolute;
  display: flex;
  row-gap: 5px;
  flex-direction: column;
  top: 50%;
  right: 40px;
  transform: translate(0, -43%);
  display: flex;
}

@media (max-width: 414px) {
  .handBlock {
    right: 15px;
  }

  .root_mobile {
    right: 50px;
    column-gap: 10px;
  }
}
