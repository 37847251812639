.root_mobile {
  position: absolute;
  left: 24px;
  bottom: 164px;
}

.root_desktop {
  position: absolute;
  bottom: 90px;
  left: 40px;
}

.root_joystickVisible {
  position: absolute;
  bottom: 64px;
  left: 24px;
}

.joystick div {
  backdrop-filter: blur(4px) !important;
}

.joystick button {
  width: 54px !important;
  height: 54px !important;
}
