.root {
  display: flex;
  justify-content: space-between;
  position: absolute;
}

@media (max-width: 500px) {
  .root {
    width: 370px;
  }
}

@media (max-width: 400px) {
  .root {
    width: 320px;
  }
}

.column {
  flex-direction: column;
  row-gap: 16px;
}

.row {
  flex-direction: row;
  column-gap: 16px;
}

@media (max-width: 433px) {
  .row {
    row-gap: 0px;
  }

  .column {
    column-gap: 0px;
  }
}
