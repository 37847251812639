@font-face {
  font-family: 'Poppins';
  src: url("../public/fonts/Poppins-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fredoka';
  src: url("../public/fonts/Fredoka-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}