.root {
  background: #ffffff;
  border: 4px solid #eaeaea;
  border-radius: 20px;
  display: flex;
  z-index: 2;
  overflow: hidden;
  position: absolute;
}


.rootCenter {
  transform: translate(-50%, -50%);
}